<template>
  <b-button
    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
    variant="primary"
    v-on="$listeners"
  >
    {{ this.titleText }}
  </b-button>
</template>

<script>
import {
  BButton,
} from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'

export default {
  name: 'ClxSubmitButton',
  components: {
    BButton,
  },
  directives: {
    Ripple,
  },
  props: {
    titleText: String,
    data: {
      type: String,
      default: () => 'Submit',
    },
  },
  data() {
    return {
    }
  },
}
</script>

<style scoped>

</style>
